<template>
  <default-layout>
    <div class="grid-x grid-margin-x account">
      <div class="cell auto">
        <vue-page-transition name="fade">
          <router-view></router-view>
        </vue-page-transition>
      </div>
    </div>
  </default-layout>
</template>

<script>
export default {
  name: 'UpdateLayout'
}
</script>

<style scoped></style>
